<template>
  <article
    class="p-4 bg-gray-50 dark:bg-gray-900 flex items-center justify-center pointer-events-none select-none"
    v-if="isValid"
  >
    <div class="bg-white w-full border shadow-sm py-3 rounded-lg max-w-lg">
      <div class="flex items-center px-4">
        <img class="h-12 w-12 rounded-full" :src="profilePick"/>
        <div class="ml-2">
          <div class="text-sm ">
            <span class="font-semibold">{{ profileName }}</span>
            <span class="text-gray-500"> • 1º</span>
          </div>
          <div class="text-gray-500 text-xs ">Software Engineer at Tesla, Inc</div>
          <div class="text-gray-500 text-xs flex">
            <span class="inline-block">1 min • Edited • </span>
            <svg class="inline-block ml-1 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" data-supported-dps="16x16" fill="currentColor" width="16" height="16" focusable="false">
              <path d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
            </svg>
          </div>
        </div>
      </div>
      <caption-preview
        class="text-gray-800 text-sm px-4 mt-2 leading-normal md:leading-relaxed whitespace-pre-wrap pointer-events-auto"
        v-if="hasContent"
        :rows="3"
      >
        {{ content }}
      </caption-preview>
      <div class="mt-2">
        <div class="relative">
          <div
            v-if="isLoadingCreative"
            class="absolute flex h-full items-center justify-center w-full bg-gray-500 opacity-50">
            <i class="fas fa-spin fa-spinner fa-2x text-white"></i>
          </div>
          <img
            :src="thumbnail"
            alt=""
            @error="addDefaultSrc"
          >
        </div>
      </div>
      <div class="text-gray-500 text-xs flex items-center mt-3 px-4">
        <img class="mr-0.5" src="@/assets/img/linkedin/like.svg"/>
        <img class="mr-0.5" src="@/assets/img/linkedin/praise.svg"/>
        <img class="mr-0.5" src="@/assets/img/linkedin/appreciation.svg"/>
        <span class="ml-1">47 • 26 comments</span>
      </div>
    </div>
  </article>
  <div
    class="p-4"
    v-else
  >
    <p class="font-semibold mb-6">
      {{ $t('page.content.preview.sentence.incompatible_with', { source: 'Linkedin' }) }}
    </p>
    <p
      v-html="$t('page.content.preview.sentence.linkedin_requirements-html')"
    />
  </div>
</template>

<script lang="ts">
import { useProfile } from '@/composables/profile/useProfile'
import { computed, defineComponent, unref } from 'vue'
import CaptionPreview from './CaptionPreview.vue'

export default defineComponent({
  components: { CaptionPreview },
  props: {
    thumbnail: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { profile } = useProfile()
    
    const profilePick = computed(() => {
      return unref(profile).profilePick ? unref(profile).profilePick : ''
    })

    const profileName = computed(() => {
      return unref(profile).name
    })

    const hasContent = computed(() => {
      return !!props.content.trim().length
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      addDefaultSrc,
      profilePick,
      profileName,
      hasContent
    }
  }
})
</script>
